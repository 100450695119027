import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { QuestionModel } from "../../models/questionans/questionmodel";
import { NgxSpinnerService } from "ngx-spinner";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { DataservicesService } from "src/app/shared/services/dataservice.service";
import { QuestionAnsService } from "../../services/questionans/question-ans.service";
import { AlertmodalComponent } from "src/app/shared/components/alertmodal/alertmodal.component";
import { UserRegistrationModel } from "../../models/userregistration/userregistrationmodel";
import { UserRegistrationComponent } from "../user-registration/user-registration.component";
import { AnswerModel } from "../../models/questionans/answermodel";
import { EvalutionDetailModel } from "../../models/evaluationDetail/evaluationdetailmodel";
import { UserEvaluationDetailService } from "../../services/userevaluationdetail/user-evaluation-detail.service";
import { HttpErrorResponse } from "@angular/common/http";
import { interval } from "rxjs";
import { timeout, delay } from "rxjs/operators";
import { SubjectModel } from "../../models/questionans/subjectmodel";
import { StopExerciseComponent } from "../../modal/stop-exercise/stop-exercise.component";
import { TimeoutComponent } from "../../modal/timeout/timeout.component";
import { Router, ActivatedRoute } from "@angular/router";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { FinishComponent } from "../../modal/finish/finish.component";
import { environment } from "src/environments/environment";
import { GTMService } from "src/app/core/services/gtm.service";
import { SEOService } from "src/app/seo/services/seo.service";
import { SEOdetailService } from "src/app/seo/services/seodetail.service";
import { LinksAndMeta } from "src/app/seo/Model/linksandmeta.model";
declare var document: any;
declare var $: any;

@Component({
  selector: "app-question-ans",
  templateUrl: "./question-ans.component.html",
  styleUrls: ["./question-ans.component.css"],
  animations: [
    trigger("changeDivFadeInFadeOut", [
      state(
        "initial",
        style({
          transform: "translateX(-150%)",
          opacity: "0",
        })
      ),
      state(
        "middel",
        style({
          transform: "translateX(0px)",
          opacity: "1",
        })
      ),
      state(
        "final",
        style({
          transform: "translateX(150%)",
          opacity: "0",
        })
      ),
      transition("initial=>middel", animate("1000ms")),
      transition("middel=>final", animate("1000ms")),
    ]),
  ],
})
export class QuestionAnsComponent implements OnInit, AfterViewInit {
  constructor(
    private Spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private sharedService: DataservicesService,
    private questionAnsService: QuestionAnsService,
    private userEvaluationDetailService: UserEvaluationDetailService,
    private router: Router,
    private route: ActivatedRoute,
    private elem: ElementRef,
    private gtmService: GTMService,
    private seo: SEOService,
    private seoDetail: SEOdetailService
  ) {}

  public subjects: SubjectModel[] = [];
  public question: QuestionModel;
  public nextQuestionIndex: number;
  public UserModel: UserRegistrationModel;
  public evalutionDetailModel: EvalutionDetailModel;
  public defaultAns: AnswerModel;
  public presentComplete: number;
  public width = 100;
  public timerLock = 0;
  public intrvalLocal: any;
  public timerIntrval: any;
  public timeOutTime: 200;
  public seconds;
  public IsShow;
  public defaultSubject: SubjectModel;
  public subjectRadioVal: any;
  public ansRadioVal: any;
  public totalQuetion: number;
  public isYiddishStudent: boolean;
  public isError: boolean;
  public error: string;
  public bothMulti: string;
  public nextMulti: string;
  public subjectBtnStyle: string[] = [];
  public translationBtnStyle: string[] = [];
  public sendBtnStyle: any;
  public setQuetionAni: any;
  public showPart2: boolean;
  public showPart1: boolean;
  public isBoy: boolean;
  public isFromDesktop: boolean;
  public isAnsSelected: boolean;

  private thisPageSeoDetail: LinksAndMeta;

  modalRef: BsModalRef;
  @ViewChild("myBar", { static: false }) template: ElementRef;

  ngOnInit() {
    this.init();
    this.route.queryParamMap.subscribe((map) => {
      this.isFromDesktop =
        map.get("isDesktop") != null ? JSON.parse(map.get("isDesktop")) : false;
    });
    this.modalRef = this.modalService.show(UserRegistrationComponent, {
      backdrop: "static",
      keyboard: false,
      ignoreBackdropClick: true,
      // class: "modal-dialog-centered",
    });
    this.gtmService.pushEventTag(
      "User registration modal loaded",
      "evaluation",
      "click",
      "User is displayed registration modal for screening"
    );
    $("modal-container").addClass("hide-scrollbar");
    this.modalRef.content.onClose.subscribe((result) => {
      if (result !== null) {
        this.gtmService.pushEventTag(
          "Screening evaluation tooltip loaded",
          "evaluation",
          "click",
          "User registred successfully and tooltip for screenig is displayed"
        );
        this.UserModel = result;
        this.UserModel.Age = this.calcAge(this.UserModel.ChildDob).toString();
        this.question = new QuestionModel();
        this.isYiddishStudent = this.UserModel.LanguageId.toString() === "2";
        this.isBoy = this.UserModel.Gender.toUpperCase() === "BOY";
        if (this.isYiddishStudent) {
          this.defaultAns.Answer = "איך ווייס נישט";
          this.bothMulti = "ביידע";
          this.nextMulti = "ווייטער";
        } else {
          this.defaultAns.Answer = "I don’t know";
          this.bothMulti = "Both";
          this.nextMulti = "Next";
        }
        if (this.showPart1) {
          this.getSubjects();
        }
      }
    });

    this.seoDetail.getThisRouteSEODetail("exercise").subscribe((response) => {
      if (response) {
        // console.log(response);
        this.thisPageSeoDetail = response;
        this.seo.updateTitle(this.thisPageSeoDetail.PageTitle);
        this.seo.updateTag({
          name: "meta",
          content: this.thisPageSeoDetail.MetaData,
        });
        this.seo.updateTag({
          name: "keywords",
          content: this.thisPageSeoDetail.Keyword,
        });
      } else {
        console.log("exercise SEO Response is null");
      }
    });
  }

  ngAfterViewInit(): void {
    $(".hydrated").css("overflow", "hidden");
    $("header").addClass("v2 fixed_header");
    // const boxwidth =
    //   this.elem.nativeElement.querySelector(".upperbox").offsetWidth;
    // this.elem.nativeElement.querySelector(".upperbox2").style.width =
    //   boxwidth + "px";
    // const boxwidth = $('.upperbox').offsetWidth;
    // $('.upperbox2').style.width = boxwidth + "px"
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    this.modalRef.hide();
    this.Spinner.hide();
    clearInterval(this.intrvalLocal);
    clearInterval(this.timerIntrval);

    this.seo.removeTag("meta");
    this.seo.removeTag("keywords");
    $("header").removeClass("v2  fixed_header");
  }

  init(): void {
    this.question = new QuestionModel();
    this.UserModel = new UserRegistrationModel();
    this.evalutionDetailModel = new EvalutionDetailModel();
    this.defaultAns = new AnswerModel();
    this.UserModel.Age = "-";
    this.IsShow = true;
    this.UserModel.Language = "-";
    this.UserModel.ChildFName = "-";
    this.defaultAns.AnswerId = 0;
    this.defaultAns.IsNotSure = true;
    this.defaultAns.IsTrueAns = false;
    this.presentComplete = 0;
    this.seconds = 30;
    this.defaultSubject = new SubjectModel();
    this.defaultSubject.SubjectId = 0;
    this.defaultSubject.IsNotSureSubject = true;
    this.evalutionDetailModel = new EvalutionDetailModel();
    this.evalutionDetailModel.Score = 0;
    this.nextQuestionIndex = 0;
    this.subjectRadioVal = 0;
    this.ansRadioVal = 0;
    this.isError = false;
    this.sendBtnStyle = "";
    this.isFromDesktop = false;
    // this.showPart1 = environment.Part1Enable;
    // this.showPart2 = environment.Part2Enable;
    this.showPart1 = true;
    this.showPart2 = false;
    this.isYiddishStudent = true;
    for (let i = 0; i < 6; i++) {
      this.subjects[i] = new SubjectModel();
    }
    for (let index = 0; index < 5; index++) {
      this.subjectBtnStyle[index] = "";
      this.translationBtnStyle[index] = "";
    }
  }

  calcAge(dob: any): number {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  hideButn: boolean = false;
  getQuestions(LanguageId, RegistrationId): void {
    this.Spinner.show();
    this.IsShow = true;
    this.questionAnsService
      .getQuestion(LanguageId, RegistrationId.toString())
      .subscribe(
        (qm) => {
          this.Spinner.hide();
          this.question = qm;
          this.isAnsSelected = false;
          this.hideButn = true;
          this.presentComplete = Math.floor(
            (this.question.NoOfAttemptQuestion / this.question.TotalQuetion) *
              100
          );
          this.nextQuestionIndex = this.question.NoOfAttemptQuestion;
          this.sendBtnStyle = {
            animation: "",
          };
          if (
            this.question !== null &&
            this.question.NoOfAttemptQuestion < this.question.TotalQuetion
          ) {
            if (this.isYiddishStudent) {
              this.setQuetionAni = {
                animation: "1s animate-Yquestion-in",
              };
            } else {
              this.setQuetionAni = {
                animation: "1s animate-question-in",
              };
            }
            this.nextQuestionIndex = this.question.NoOfAttemptQuestion + 1;
            this.IsShow = false;
            // this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
            this.closeTip ? this.move(100, this.timeOutTime) : null;
          } else {
            // this.showPart1 = false;
            // this.showPart2 = true;
            this.sharedService.add(
              "",
              "",
              this.UserModel.RegistrationId,
              null,
              null,
              null,
              this.UserModel.GuardianEmail
            );
            this.modalRef = this.modalService.show(FinishComponent, {
              keyboard: true,
              ignoreBackdropClick: true,
              class: "modal-dialog-centered",
            });
            this.sendReport(this.UserModel.RegistrationId, false);
          }
        },
        (error) => {
          this.Spinner.hide();
          this.sharedService.add("Quetions", "Server error.");
          this.modalRef = this.modalService.show(AlertmodalComponent, {
            class: "modal-sm",
          });
          console.log(error);
        }
      );
  }

  getSubjects(): void {
    this.Spinner.show();
    this.IsShow = false;
    this.questionAnsService.getSubjects().subscribe(
      (sm) => {
        this.subjects = sm;
        this.getQuestions(
          this.UserModel.LanguageId,
          this.UserModel.RegistrationId
        );
        // this.getQuestions('2', '2');
      },
      (error) => {
        this.Spinner.hide();
        this.sharedService.add("Quetions", "Server error.");
        this.modalRef = this.modalService.show(AlertmodalComponent, {
          class: "modal-sm",
        });
        console.log(error);
      }
    );
  }

  saveTimeOutProgress() {
    this.isAnsSelected = true;
    this.evalutionDetailModel.QuestionId = this.question.QuestionId;
    this.evalutionDetailModel.IsTimeout = true;
    this.evalutionDetailModel.IsNotSure = false;
    this.evalutionDetailModel.RegistrationId = this.UserModel.RegistrationId;
    this.evalutionDetailModel.RuleId = this.question.RuleId;
    this.evalutionDetailModel.AnswerId = 0;
    this.evalutionDetailModel.SubjectId = 0;
    this.evalutionDetailModel.Score = 0;
    this.IsShow = true;
    this.sendBtnStyle = {
      animation: "0.5s anime-btn infinite alternate",
    };
    // this.addEvalutionDetali(true);
  }

  validateAns(item: AnswerModel, optionId): void {
    this.isAnsSelected = true;
    this.evalutionDetailModel.QuestionId = this.question.QuestionId;
    this.evalutionDetailModel.RegistrationId = this.UserModel.RegistrationId;
    this.evalutionDetailModel.IsTimeout = false;
    this.evalutionDetailModel.AnswerId = item.AnswerId;
    this.evalutionDetailModel.IsNotSure = item.IsNotSure;
    this.evalutionDetailModel.Score = item.IsTrueAns ? 1 : 0;
    this.evalutionDetailModel.RuleId = this.question.RuleId;
    this.setTranslationButtonStyle(optionId);
    this.sendBtnStyle = {
      animation: "0.5s anime-btn infinite alternate",
    };
    this.gtmService.pushEventTag(
      "validate selected answer",
      "screening evaluation",
      "click",
      "User selected the answer"
    );
  }

  validateSubject(item: SubjectModel): void {
    this.evalutionDetailModel.QuestionId = this.question.QuestionId;
    this.evalutionDetailModel.RegistrationId = this.UserModel.RegistrationId;
    this.evalutionDetailModel.IsTimeout = false;
    this.evalutionDetailModel.IsSubjectAns =
      item.SubjectId === this.question.SubjectId;
    this.evalutionDetailModel.SubjectId = item.SubjectId;
    this.evalutionDetailModel.IsNotSureSubject = item.IsNotSureSubject;
    this.setSubjectButtonStyle(item.SubjectId);
    this.gtmService.pushEventTag(
      "validate selected subject",
      "screening evaluation",
      "click",
      "User select the answer"
    );
  }

  setSubjectButtonStyle(SubjectId) {
    if (SubjectId === 0) {
      this.subjectBtnStyle[0] = "";
      this.subjectBtnStyle[1] = "";
      this.subjectBtnStyle[2] = "";
      this.subjectBtnStyle[3] = "";
      this.subjectBtnStyle[4] = "select-btn-border";
    } else if (SubjectId === 1) {
      this.subjectBtnStyle[0] = "select-btn-border";
      this.subjectBtnStyle[1] = "";
      this.subjectBtnStyle[2] = "";
      this.subjectBtnStyle[3] = "";
      this.subjectBtnStyle[4] = "";
    } else if (SubjectId === 2) {
      this.subjectBtnStyle[0] = "";
      this.subjectBtnStyle[1] = "select-btn-border";
      this.subjectBtnStyle[2] = "";
      this.subjectBtnStyle[3] = "";
      this.subjectBtnStyle[4] = "";
    } else if (SubjectId === 3) {
      this.subjectBtnStyle[0] = "";
      this.subjectBtnStyle[1] = "";
      this.subjectBtnStyle[2] = "select-btn-border";
      this.subjectBtnStyle[3] = "";
      this.subjectBtnStyle[4] = "";
    } else if (SubjectId === 4) {
      this.subjectBtnStyle[0] = "";
      this.subjectBtnStyle[1] = "";
      this.subjectBtnStyle[2] = "";
      this.subjectBtnStyle[3] = "select-btn-border";
      this.subjectBtnStyle[4] = "";
    } else if (SubjectId === 5) {
      this.subjectBtnStyle[0] = "select-btn-border";
      this.subjectBtnStyle[1] = "";
      this.subjectBtnStyle[2] = "select-btn-border";
      this.subjectBtnStyle[3] = "";
      this.subjectBtnStyle[4] = "";
    } else if (SubjectId === 6) {
      this.subjectBtnStyle[0] = "";
      this.subjectBtnStyle[1] = "select-btn-border";
      this.subjectBtnStyle[2] = "";
      this.subjectBtnStyle[3] = "select-btn-border";
      this.subjectBtnStyle[4] = "";
    }
  }

  setTranslationButtonStyle(answertId) {
    if (answertId === 0) {
      this.translationBtnStyle[0] = "";
      this.translationBtnStyle[1] = "";
      this.translationBtnStyle[2] = "";
      this.translationBtnStyle[3] = "select-btn-border";
    } else if (answertId === 1) {
      this.translationBtnStyle[0] = "select-btn-border";
      this.translationBtnStyle[1] = "";
      this.translationBtnStyle[2] = "";
      this.translationBtnStyle[3] = "";
    } else if (answertId === 2) {
      this.translationBtnStyle[0] = "";
      this.translationBtnStyle[1] = "select-btn-border";
      this.translationBtnStyle[2] = "";
      this.translationBtnStyle[3] = "";
    } else if (answertId === 3) {
      this.translationBtnStyle[0] = "";
      this.translationBtnStyle[1] = "";
      this.translationBtnStyle[2] = "select-btn-border";
      this.translationBtnStyle[3] = "";
    }
  }

  AllAns: any = [];
  saveEvalutionDetails(): void {
    if (
      this.evalutionDetailModel.RegistrationId !== undefined &&
      this.evalutionDetailModel.RegistrationId !== 0
    ) {
      this.AllAns = this.question.AnswerEvaModels;
      let op: number = 0;
      for (let i = 0; i < this.AllAns.length; i++) {
        const element = this.AllAns[i];
        if (element.AnswerId != this.evalutionDetailModel.AnswerId) {
          op = op + 1;
          this.evalutionDetailModel["OptionalAns" + op] = element.AnswerId;
        }
      }
      this.question = new QuestionModel();
      this.question.AnswerEvaModels.length = 0;
      this.template.nativeElement.style.width = 100 + "%";
      this.template.nativeElement.style.backgroundColor = "#67b9ff";
      clearInterval(this.intrvalLocal);
      clearInterval(this.timerIntrval);
      if (this.isYiddishStudent) {
        this.setQuetionAni = {
          animation: "1s animate-Yquestion-out",
        };
      } else {
        this.setQuetionAni = {
          animation: "1s animate-question-out",
        };
      }
      this.addEvalutionDetali(false);
      this.hideButn = false;
    } else {
      this.isError = true;
      this.error = "Please select one of the answer.";
    }
    this.gtmService.pushEventTag(
      "save evaluation detail loaded",
      "screening evaluation",
      "click",
      "User move to next question"
    );
  }

  addEvalutionDetali(isTimeOut: boolean) {
    this.Spinner.show();
    this.userEvaluationDetailService
      .addEvalutionDetali(this.evalutionDetailModel)
      .subscribe(
        async (res) => {
          if (res.IsSuccessfully) {
            this.evalutionDetailModel = new EvalutionDetailModel();
            this.evalutionDetailModel.Score = 0;
            this.seconds = 30;
            this.timerLock = 0;
            this.subjectRadioVal = 0;
            this.ansRadioVal = 0;
            this.error = "";
            this.isError = false;
            for (let index = 0; index < 5; index++) {
              this.subjectBtnStyle[index] = "";
              this.translationBtnStyle[index] = "";
            }
            this.getQuestions(
              this.UserModel.LanguageId,
              this.UserModel.RegistrationId
            );
            // if (isTimeOut) {
            //   this.Spinner.hide();
            //   this.modalRef = this.modalService.show(TimeoutComponent, { ignoreBackdropClick: true, class: 'modal-dialog-centered' });
            //   this.modalRef.content.onClose.subscribe(result => {
            //     this.Spinner.show();
            //     this.getQuestions(this.UserModel.LanguageId, this.UserModel.RegistrationId);
            //   });
            // } else {
            //   this.getQuestions(this.UserModel.LanguageId, this.UserModel.RegistrationId);
            // }
          }
        },
        (err: HttpErrorResponse) => {
          this.Spinner.hide();
          console.log(err);
        }
      );
  }

  move(width, timeOutTime): void {
    this.timerIntrval = setInterval(() => {
      this.seconds--;
      if (this.seconds <= 0) {
        clearInterval(this.timerIntrval);
      }
    }, 1000);
    if (this.timerLock === 0) {
      this.timerLock = 1;
      this.width = width;
      this.intrvalLocal = setInterval(() => {
        this.frame();
      }, 300);
    }
  }

  frame() {
    if (this.width === 50) {
      // this.template.nativeElement.style.backgroundColor = 'orange';
    }
    if (this.width === 35) {
      // this.template.nativeElement.style.backgroundColor = '#ff9567';
    }
    if (this.width === 15) {
      // this.template.nativeElement.style.backgroundColor = '#ff6796';
    }
    if (this.width <= 0) {
      clearInterval(this.intrvalLocal);
      this.saveTimeOutProgress();
      this.timerLock = 0;
    } else {
      this.width--;
      this.template.nativeElement.style.width = this.width + "%";
      this.template.nativeElement.style.backgroundPositionX = "40%";
    }
  }

  stopExercise() {
    this.modalRef = this.modalService.show(StopExerciseComponent, {
      ignoreBackdropClick: true,
      class: "modal-dialog-centered",
    });
    this.gtmService.pushEventTag(
      "stop exercise for evaluation",
      "screening evaluation",
      "click",
      "User stop screening"
    );
    this.modalRef.content.onClose.subscribe((result) => {
      if (result !== null && result === true) {
        if (this.isFromDesktop) {
          this.getTotalPercentage();
        } else {
          this.openFinishDialog();
        }
      }
    });
  }

  openFinishDialog() {
    clearInterval(this.intrvalLocal);
    clearInterval(this.timerIntrval);
    this.sharedService.add(
      "",
      "",
      this.UserModel.RegistrationId,
      null,
      null,
      null,
      this.UserModel.GuardianEmail
    );
    this.modalRef = this.modalService.show(FinishComponent, {
      keyboard: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered",
    });
    this.gtmService.pushEventTag(
      "View Report modal loaded",
      "post evaluation",
      "click",
      "User stop screening"
    );
    this.sendReport(this.UserModel.RegistrationId, true);
  }

  sendReport(RegistrationId, isStopExe) {
    this.IsShow = false;
    this.questionAnsService.sendReport(RegistrationId.toString()).subscribe(
      (sm) => {
        this.Spinner.hide();
        // if (sm !== null && sm !== undefined) {
        //   if (isStopExe) {
        //     this.router.navigate(['/']);
        //   }
        // }
      },
      (error) => {
        this.Spinner.hide();
        this.sharedService.add("Report", "Server error.");
        this.modalRef = this.modalService.show(AlertmodalComponent, {
          class: "modal-sm",
        });
        console.log(error);
      }
    );
  }

  getTotalPercentage() {
    this.Spinner.show();
    this.questionAnsService
      .getTotalPercentage(this.UserModel.RegistrationId.toString())
      .subscribe(
        (response) => {
          const percentage = Number(response);
          this.Spinner.hide();
          clearInterval(this.intrvalLocal);
          clearInterval(this.timerIntrval);
          this.sharedService.add(
            "",
            "",
            this.UserModel.RegistrationId,
            percentage,
            this.UserModel.GuardianName,
            null,
            this.UserModel.GuardianEmail
          );
          this.modalRef = this.modalService.show(FinishComponent, {
            keyboard: true,
            ignoreBackdropClick: true,
            class: "modal-dialog-centered",
          });
          this.gtmService.pushEventTag(
            "View Report modal loaded",
            "post evaluation",
            "click",
            "User from Exe stopped screening"
          );
          this.sendReport(this.UserModel.RegistrationId, true);
        },
        (error) => {
          this.Spinner.hide();
          this.sharedService.add("Fetch Percentage", "Server error.");
          this.modalRef = this.modalService.show(AlertmodalComponent, {
            class: "modal-sm",
          });
          console.log(error);
        }
      );
  }

  openDownload() {
    this.gtmService.pushEventTag(
      "Dowload Link clicked",
      "evaluation",
      "click",
      "User redirected to download page in new window"
    );
    const link = environment.hostingURL + "download/login";
    window.open(link, "_blank");
  }

  openAboutApp() {
    this.gtmService.pushEventTag(
      "About app Link clicked",
      "evaluation",
      "click",
      "User redirected to about app page in new window"
    );
    const link = environment.hostingURL + "aboutapp";
    window.open(link, "_blank");
  }

  openHome() {
    this.gtmService.pushEventTag(
      "Home Link clicked",
      "evaluation",
      "click",
      "User redirected to home page in new window"
    );
    const link = environment.hostingURL + "home";
    window.open(link, "_blank");
  }
  mainHome() {
    this.gtmService.pushEventTag(
      "Home Link clicked",
      "evaluation",
      "click",
      "User redirected to home page in new window"
    );
    const link = environment.hostingURL + "home";
    window.open(link, "_blank");
  }

  openReviews() {
    this.gtmService.pushEventTag(
      "Review Link clicked",
      "evaluation",
      "click",
      "User redirected to review page in new window"
    );
    const link = environment.hostingURL + "reviews";
    window.open(link, "_blank");
  }

  closeTip: boolean = false;
  hideTip() {
    this.CloseOverlay();
    this.closeTip = true;
    $(".start-overlay").hide();
    $(".buttoner").hide();
    $(".tip").hide();
    this.move(100, this.timeOutTime);
    this.gtmService.pushEventTag(
      "start exercise for screening loaded",
      "screening evaluation",
      "click",
      "User start screening"
    );
  }

  CloseOverlay() {
    $(".tip, .page_overlay").addClass("d-none");
    $(".understand_bg").addClass("d-none");
  }
}
