import { Injectable } from "@angular/core";
import { EncryptDecryptService } from "./encrypt-decrypt.service";
@Injectable({
  providedIn: "root",
})
export class DataservicesService {
  constructor(private encrdecrService: EncryptDecryptService) {}
  public msg: string;
  public title: string;
  public isConfirmation: boolean;
  public regId: string;
  public percentage: number;
  public username: string;
  public guardianEmail: string;
  private Guid = "0ef1705aff564c319643a287081d7b8d";
  add(
    title: string,
    message: string,
    regId?,
    percentage?,
    username?,
    isConfirmation = false,
    guardianEmail?
  ) {
    this.msg = message;
    this.title = title;
    this.regId = regId;
    this.percentage = percentage;
    this.username = username;
    this.isConfirmation = isConfirmation;
    this.guardianEmail = guardianEmail;
  }

  public EncryptValue(value): string {
    // const cipherKey = this.encrdecrService.encrypt(this.Guid, 'test');
    const cipherValue = this.encrdecrService.encrypt(this.Guid, value);
    return cipherValue;
  }

  public DecryptValue(cipherValue): string {
    // const cipherKey = this.encrdecrService.encrypt(this.Guid, 'test');
    const value = this.encrdecrService.decrypt(this.Guid, cipherValue);
    return value;
  }
}
