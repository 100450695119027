import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DataservicesService } from "../../services/dataservice.service";
import { GTMService } from "src/app/core/services/gtm.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-alertmodal",
  templateUrl: "./alertmodal.component.html",
  styleUrls: ["./alertmodal.component.css"],
})
export class AlertmodalComponent implements OnInit {
  public onClose: Subject<boolean>;
  public msg: string;
  public title: string;
  public isConfirmation: boolean = false;
  constructor(
    public bsModalRef: BsModalRef,
    private sharedService: DataservicesService,
    private gtmService: GTMService,
    private router: Router
  ) {}

  ngOnInit() {
    this.onClose = new Subject();
    this.msg = this.sharedService.msg;
    this.title = this.sharedService.title;
    this.isConfirmation = this.sharedService.isConfirmation;
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
    this.gtmService.pushEventTag(
      "Alert message confirmed",
      "Alert message",
      "click",
      "User confirm alert box on" + this.router.url
    );
  }

  public onCancle(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
