import { HttpHeaders } from '@angular/common/http';

export const environment = {
  production: false,
  // apiUrl: 'https://haloshon.com/api/api',
  // serverUrl: 'https://haloshon.com/api/api',
  apiUrl: 'https://haloshon.com/api/api',
  serverUrl: 'https://haloshon.com/api/api',
  // apiUrl: 'https://haloshon.com/api/api',
  // serverUrl: 'https://haloshon.com/api',
  httpOptions: {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  },
  Part1Enable: true,
  Part2Enable: false,
  Critical: 60,
  Benefitial: 85,
  // hostingURL: 'https://localhost:44361/',
  hostingURL:'https://haloshon.com/',
  EXEFileName: 'EineiHaloshon_Setup.exe',

  /** Google Tag Manager Id
   * GTM-N7SS5XT is our development trackingId
   * GTM-MQ589BR is our production trackingId - to be used  ONLY WHEN HOSTINGURL IS HALOSHON.COM
   * CurrentGTMId - set to GTM-MQ589BR when hostingUrl is haloshon.com
   */
  CurrentGTMId : "GTM-MQ589BR",     //uncomment when hostingurl is devclien.haloshon.com
  //CurrentGTMId : "GTM-MQ589BR",   //uncomment when hostingurl is haloshon.com
};
