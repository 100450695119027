export class UserRegistrationModel {
  constructor() {}
  public RegistrationId: number;
  public ChildFName: string;
  public ChildLName: string;
  public ChildDob: Date;
  public LanguageId: string;
  public GuardianName: string;
  public GuardianEmail: string;
  public Age: string;
  public ServersideForEmail = false;
  public IsAlredeyReg = false;
  public Language: string;
  public StudentId: number;
  public Gender: string;
  public GuardianRole: string;
  public StartTime: string;
  public ChildDobStr: string;
  public MobileNo: string;
  public Howdidyouhearaboutus: string;
  public PrevRegId: number;
}

export interface CountryCodes {
  Country: string;
  CountryCode: string;
}
