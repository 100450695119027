import { Component, OnInit } from "@angular/core";
import { DataservicesService } from "src/app/shared/services/dataservice.service";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { environment } from "src/environments/environment";
import { GTMService } from "src/app/core/services/gtm.service";
import { QuestionAnsService } from "../../services/questionans/question-ans.service";
import { AlertmodalComponent } from "src/app/shared/components/alertmodal/alertmodal.component";

@Component({
  selector: "app-finish",
  templateUrl: "./finish.component.html",
  styleUrls: ["./finish.component.css"],
})
export class FinishComponent implements OnInit {
  public isFromDesktop: boolean;
  public TotalPercentage: number;
  public BeneficialLevel: number = 85;

  constructor(
    private sharedService: DataservicesService,
    private router: Router,
    public bsModalRef: BsModalRef,
    private route: ActivatedRoute,
    private gtmService: GTMService,
    private questionAnswerService: QuestionAnsService,
    private modalService: BsModalService
  ) {}

  regId: string;
  GuardianName: string;
  GuardianEmail: string;
  ngOnInit() {
    this.regId = this.sharedService.regId;
    this.TotalPercentage = this.sharedService.percentage;
    this.GuardianName = this.sharedService.username;
    this.GuardianEmail = this.sharedService.guardianEmail;
    this.isFromDesktop = false;

    this.route.queryParamMap.subscribe((map) => {
      this.isFromDesktop =
        map.get("isDesktop") != null ? JSON.parse(map.get("isDesktop")) : false;
    });
  }

  onViewReport() {
    this.bsModalRef.hide();
    // this.router.navigate(['/evaluation/report'], { queryParams: { id: this.regId }, skipLocationChange: true });
    // this.regId = '92';
    this.gtmService.pushEventTag(
      "View Report modal unloaded",
      "post evaluation",
      "click",
      "User is redirected to new window ro view report for screening performed"
    );
    if (this.regId !== undefined) {
      const encryptedId = this.sharedService.EncryptValue(this.regId).trim();
      const re = /\//gi;
      const replace1 = encryptedId.replace(re, "^");
      const re1 = /\+/gi;
      const replace2 = replace1.replace(re1, " ");
      const link =
        environment.hostingURL +
        "evaluation/report?id=" +
        replace2 +
        "&isDesktop=false;";
      window.open(link, "_blank");
      this.router.navigate(["/home"]);
    }
  }

  onFreeTrialClick() {
    const registrationId = !Number.isNaN(+this.regId) ? +this.regId : 0;
    this.questionAnswerService.allowFreeTrial(registrationId).subscribe(
      (res: any) => {
        if (res.IsSuccessfully) {
          this.router.navigate(["/download"], {
            queryParams: {
              email: this.GuardianEmail || "",
            },
          });
        } else {
          this.sharedService.add(
            "Fail to enable you for Free Trial.",
            res.Message ||
              "Unable to provide Free Trial. Please contact adminstrator."
          );
          const modalRef = this.modalService.show(AlertmodalComponent, {
            class: "modal-md",
          });
        }
      },
      (err) => {
        this.sharedService.add(
          "Failed to complete request.",
          err.Message || "Please contact adminstrator."
        );
        const modalRef = this.modalService.show(AlertmodalComponent, {
          class: "modal-md",
        });
      }
    );
  }

  closeModal() {
    this.gtmService.pushEventTag(
      "View Report modal unloaded",
      "post evaluation",
      "click",
      "User didnot view report and is redirected to home page"
    );
    this.bsModalRef.hide();
    this.router.navigateByUrl("home");
  }
}
