import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
  CountryCodes,
  UserRegistrationModel,
} from "../../models/userregistration/userregistrationmodel";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { DataservicesService } from "src/app/shared/services/dataservice.service";
import { UserRegistrationService } from "../../services/userregistration/user-registration.service";
import { AlertmodalComponent } from "src/app/shared/components/alertmodal/alertmodal.component";
import { HttpErrorResponse } from "@angular/common/http";
import { Subject } from "rxjs";
import { LanguageModel } from "../../models/language/languagemodel";
import { Router, ActivatedRoute } from "@angular/router";
import { GTMService } from "src/app/core/services/gtm.service";

@Component({
  selector: "app-user-registration",
  templateUrl: "./user-registration.component.html",
  styleUrls: ["./user-registration.component.css"],
})
export class UserRegistrationComponent implements OnInit, OnDestroy {
  // Constant Variables
  readonly GUARDIAN_CHILD_RELATION = {
    Parent: [
      "Conducting Screening for my son",
      "Conducting Screening for my daughter",
    ],
    Student: ["I'm a Boy", "I'm a Girl"],
    Teacher: [
      "Conducting Screening for Boy Student",
      "Conducting Screening for Girl Student",
    ],
    PrivateTutor: [
      "Conducting Screening for Boy Student",
      "Conducting Screening for Girl Student",
    ],
    Evaluator: [
      "Conducting Screening for Boy Student",
      "Conducting Screening for Girl Student",
    ],
  };
  // Data Variales
  UserModel: UserRegistrationModel;
  languageModel: LanguageModel[] = [];

  // Reference variables
  modalRef: BsModalRef;
  minDate: Date;
  maxDate: Date;
  errors: any[];
  errorFlag: boolean;
  @ViewChild("otp", { static: false }) otp: ElementRef;
  regForm: FormGroup;
  isEmailSubmitted: boolean = false;
  isEmailAlreadyValidated: boolean = false;

  // Class variables
  onClose: Subject<UserRegistrationModel>;
  queryEmail: string;
  label: any;
  countryCodes: CountryCodes[] = [];
  previousRegUser: UserRegistrationModel = undefined;
  step = 1;

  isMobile = false;

  constructor(
    private Spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private sharedService: DataservicesService,
    public bsModalRef: BsModalRef,
    private router: Router,
    private route: ActivatedRoute,
    private gtmService: GTMService,
    private fb: FormBuilder,
    private userRegisterationService: UserRegistrationService
  ) {}

  // Lifecycle Hooks

  ngOnInit() {
    // Initialization
    this.UserModel = new UserRegistrationModel();
    this.onClose = new Subject();
    this.errorFlag = false;
    this.maxDate = new Date();
    this.route.queryParamMap.subscribe((map) => {
      this.queryEmail = map.get("email");
    });
    this.createRegForm();
    this.getCountryCode();
  }

  onOptionChange() {
    debugger;
  }

  ngAfterViewInit() {
    this.getEmailSuffix();
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  }

  ngOnDestroy() {
    this.Spinner.hide();
    $("html").css("overflow", "");
  }

  // Api calls
  onEmailVerifyClick() {
    if (
      !this.isEmailSubmitted &&
      this.getRegFormControl("guardianEmail").valid
    ) {
      this.Spinner.show();
      this.userRegisterationService
        .registerEmail(this.getRegFormControl("guardianEmail").value || "")
        .subscribe(
          (response) => {
            this.Spinner.hide();
            if (response["Message"] === "ALREADYVALIDATE") {
              this.isEmailAlreadyValidated = true;
              this.regForm.removeControl("otp");
              this.getRegFormControl("guardianEmail").disable();
              if (response.Data) {
                this.previousRegUser = (JSON.parse(response.Data) as any[])[0];
                this.populateFormWithUser();
              }
            } else if (response["IsSuccessfully"]) {
              this.regForm.addControl(
                "otp",
                this.fb.group({
                  digit1: ["", [Validators.maxLength(1), Validators.required]],
                  digit2: ["", [Validators.maxLength(1), Validators.required]],
                  digit3: ["", [Validators.maxLength(1), Validators.required]],
                  digit4: ["", [Validators.maxLength(1), Validators.required]],
                })
              );
              this.isEmailSubmitted = true;
            } else {
              console.error(
                `Error: ${response["ErrorType"]} - ${response["Message"]}`
              );
              this.getRegFormControl("guardianEmail").setErrors({
                server:
                  response.Message || "Sorry! Error occured on server side.",
              });
            }
          },
          (err) => {
            console.error(`Error: ${err["ErrorType"]} - ${err["Message"]}`);
            this.getRegFormControl("guardianEmail").setErrors({
              pattern: true,
            });
            this.Spinner.hide();
          }
        );
    }
  }

  onResendEmailClick() {
    this.isEmailSubmitted = false;
    this.getRegFormControl("guardianEmail").reset(null);
    this.getRegFormControl("otp").reset();
  }

  onRegFormSubmit() {
    // if (this.regForm.invalid) {
    //   Object.keys(this.regForm.controls).forEach((field) => {
    //     if (field !== "mobileNo" && field !== "howdidyouhearaboutus") {
    //       const control = this.regForm.get(field);
    //       control.markAsTouched({ onlySelf: true });
    //       control.markAsDirty();
    //       // this.focusOutFunction(field);
    //     }
    //   });
    //   return;
    // }
    if (this.isEmailAlreadyValidated) {
      this.checkForDuplicateStudent();
    } else 
    if (!this.isEmailSubmitted) {
      this.onEmailVerifyClick();
    } else if (this.getRegFormControl("otp").valid) {
      this.Spinner.show();
      const otp = this.getOtpValue();
      this.userRegisterationService
        .verifyOTP(
          this.getRegFormControl("guardianEmail").value || "",
          otp || ""
        )
        .subscribe(
          (response) => {
            if (response["IsSuccessfully"]) {
              this.isEmailAlreadyValidated = true;
              this.getRegFormControl("guardianEmail").disable();
              const data = JSON.parse(response.Data);
              if (data.length !== 0) {
                this.previousRegUser = (data as any[])[0];
                this.populateFormWithUser();
              }
              this.Spinner.hide();
              this.checkForDuplicateStudent();
            } else {
              this.Spinner.hide();
              console.error(
                `Error: ${response["ErrorType"]} - ${response["Message"]}`
              );
              this.getRegFormControl("otp").setErrors(Validators.pattern);
              for (let i = 0; i < 4; i++) {
                this.getOtpFormControls(`digit${i + 1}`).setValue("");
                this.getOtpFormControls(`digit${i + 1}`).setErrors(
                  Validators.pattern
                );
              }
              document.getElementById("digit1").focus();
            }
          },
          (err) => {
            console.error(`Error: ${err["ErrorType"]} - ${err["Message"]}`);
            this.Spinner.hide();
          }
        );
    }
  }

  checkForDuplicateStudent() {
    this.Spinner.show();
    this.prepareUserRegModel();
    this.userRegisterationService
      .checkForDuplicateStudent(this.UserModel)
      .subscribe(
        (regId: number) => {
          if (regId >= -1) 
            {
            // this.sharedService.add(
            //   "This Child has already registered",
            //   "would you like to redo the screening again or have you already made the program and would like to screen again?",
            //   null,
            //   null,
            //   null,
            //   true
            // );
            // this.modalRef = this.modalService.show(AlertmodalComponent, {
            //   class: "modal-md",
            // });
            // this.modalRef.content.onClose.subscribe((result) => {
            //   if (result) {
            //     this.UserModel.PrevRegId = regId;
            //     this.saveUser();
            //   }
            // });
            this.UserModel.PrevRegId = regId;
            this.saveUser();
          }
        },
        (err) => {
          this.sharedService.add(
            "Check for Duplicate",
            "Failed to check duplicate user"
          );
          this.modalRef = this.modalService.show(AlertmodalComponent, {
            class: "modal-sm",
          });
          console.log(err);
          this.clearFormData();
        },
        () => this.Spinner.hide()
      );
  }

  saveUser() {
    this.Spinner.show();
    this.userRegisterationService.addUser(this.UserModel).subscribe(
      (res) => {
        this.Spinner.hide();
        if (res[0].IsSuccessfully) {
          this.errorFlag = false;
          const user = JSON.parse(res[0].Data);
          this.UserModel.RegistrationId = user.RegistrationId;
          this.UserModel.Language = user.Language;
          this.UserModel.IsAlredeyReg = user.IsAlredeyReg;
          this.onClose.next(this.UserModel);
          this.bsModalRef.hide();
          this.gtmService.pushEventTag(
            "User registered successfully",
            "evaluation",
            "click",
            "User registered successfully and is proceeding to screening"
          );
        } else {
          this.errorFlag = true;
          this.errors = res;
          this.Spinner.hide();
          this.sharedService.add("User Save", "Failed to save user");
          this.modalRef = this.modalService.show(AlertmodalComponent, {
            class: "modal-sm",
          });
        }
      },
      (err: HttpErrorResponse) => {
        this.Spinner.hide();
        this.sharedService.add("User Save", "Failed to save user");
        this.modalRef = this.modalService.show(AlertmodalComponent, {
          class: "modal-sm",
        });
        console.log(err);
        this.clearFormData();
      }
    );
  }

  getLanguages(): void {
    this.Spinner.show();
    this.userRegisterationService.getLanguages().subscribe(
      (am) => {
        this.Spinner.hide();
        if (am != null) {
          this.languageModel = am;
        } else {
          this.sharedService.add(
            "User",
            "Language Not found ! Please Contact Administrator"
          );
          this.modalRef = this.modalService.show(AlertmodalComponent, {
            class: "modal-sm",
          });
        }
      },
      (err: HttpErrorResponse) => {
        this.Spinner.hide();
        console.log("Error =>>" + err);
        this.sharedService.add("User", "Error occur while fetching Language");
        this.modalRef = this.modalService.show(AlertmodalComponent, {
          class: "modal-sm",
        });
      }
    );
  }

  getCountryCode(): void {
    this.userRegisterationService
      .getCountryCodeList()
      .subscribe((countryCodeList: CountryCodes[]) => {
        this.countryCodes = countryCodeList;
        this.regForm.get("countryCode").setValue(1);
      });
  }

  // Template Event Handlers

  isFocusedOutInValid(ctrlName: string) {
    const element: AbstractControl = this.getRegFormControl(ctrlName);
    return this.isValueEmpty(element.value) && element.invalid && element.dirty;
  }

  isFocusedOutValid(ctrlName: string) {
    const element: AbstractControl = this.getRegFormControl(ctrlName);
    return this.isValueEmpty(element.value) && element.valid && element.dirty;
  }

  isFocusIn(ctrlName: string) {
    const element: AbstractControl = this.getRegFormControl(ctrlName);
    return !this.isValueEmpty(element.value);
  }

  // focusFunction(f: string) {
  //   $("#" + f).css("top", "0%");
  //   $("#" + f).css("font-size", "12px");
  // }

  // focusOutFunction(ctrlName: string) {
  //   const element: AbstractControl = this.getRegFormControl(ctrlName);
  //   if (!this.isValueEmpty(element.value)) {
  //     return;
  //   } else if (!element.valid && !element.pristine) {
  //     $("#" + ctrlName).css("top", "34%");
  //     $("#" + ctrlName).css("font-size", "15px");
  //   } else {
  //     $("#" + ctrlName).css("top", "50%");
  //     $("#" + ctrlName).css("font-size", "15px");
  //   }
  // }

  next(event: any, ind: number) {
    const e: KeyboardEvent = event;
    if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105)
    ) {
      if (event.target.value.length) {
        if (ind < 3) {
          const next = this.otp.nativeElement[ind + 1];
          next.focus();
        } else if (ind >= 3 && this.regForm.get("otp").valid) {
          this.onRegFormSubmit();
        }
      }
    }
  }

  back(event: any, ind: number) {
    const e: KeyboardEvent = event;
    if (e.keyCode === 8) {
      event.preventDefault();
      if (this.getOtpFormControls(`digit${ind + 1}`).value.toString().length) {
        this.getOtpFormControls(`digit${ind + 1}`).setValue("");
      } else if (ind >= 0) {
        const prev = this.otp.nativeElement[ind - 1];
        prev.focus();
      }
    }
  }

  onDateHide($event) {
    if ($event.target.value) {
      $("#" + this.label).css("top", "0%");
      $("#" + this.label).css("font-size", "12px");
    }
  }

  clearFormData(): void {
    this.UserModel = new UserRegistrationModel();
    if (this.regForm) {
      this.regForm.reset();
    }
  }

  closeModal() {
    $("html").css("overflow", "");
    this.gtmService.pushEventTag(
      "User registration modal uloaded",
      "evaluation",
      "click",
      "User didnot register."
    );
    this.bsModalRef.hide();
    this.router.navigateByUrl("home");
  }

  // Helper Functions

  isLanSelected() {
    if (this.UserModel.LanguageId) return true;
    else return false;
  }

  getEmailSuffix() {
    this.UserModel.GuardianEmail =
      this.queryEmail != null ? this.queryEmail : "";
    this.regForm.get("guardianEmail").setValue(this.UserModel.GuardianEmail);
    // this.UserModel.GuardianEmail && this.focusFunction("guardianEmail");
  }

  createRegForm() {
    this.regForm = this.fb.group({
      inputFirstname: ["", [Validators.required, Validators.minLength(3)]],
      inputLastname: ["", [Validators.required, Validators.minLength(3)]],
      gender: ["", [Validators.required]],
      childDob: ["", [Validators.required]],
      language: ["", [Validators.required]],
      guardianName: ["", [Validators.required, Validators.minLength(3)]],
      guardianEmail: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[A-Za-z]{2,4}$"
          ),
        ],
      ],
      guardianRole: ["Parent"],
      mobileNo: [
        "",
        [
          Validators.required,
          Validators.pattern(/^(\+?\d{1,3}|\d{1,4})\s?\d{6,14}$/),
        ],
      ],
      countryCode: [""],
      howdidyouhearaboutus: ["", []],
    });
  }

  getRegFormControl(ctrlName: string) {
    return this.regForm.get(ctrlName);
  }

  isFormCtrlInvalid(ctrlName: string) {
    const control = this.regForm.get(ctrlName);
    return control.invalid && control.touched && control.dirty;
  }

  isOtpControlInvalid(ctrlName: string) {
    const control = this.regForm.get("otp").get(ctrlName);
    return control.invalid && control.touched && control.dirty;
  }

  getOtpFormControls(ctrlName: string) {
    return this.regForm.get("otp").get(ctrlName);
  }

  isValueEmpty(val: any) {
    return val == null || !(Object.keys(val) || val).length;
  }

  getOtpValue() {
    let otp = "";
    for (let i = 0; i < 4; i++) {
      otp += this.getOtpFormControls(`digit${i + 1}`).value;
    }
    return otp;
  }

  populateFormWithUser() {
    const [countryCode, mobile] = this.previousRegUser.MobileNo.split(" ");
    this.previousRegUser.ChildFName &&
      this.getRegFormControl("inputFirstname").setValue(
        this.previousRegUser.ChildFName
      );
    this.previousRegUser.ChildLName &&
      this.getRegFormControl("inputLastname").setValue(
        this.previousRegUser.ChildLName
      );
    this.previousRegUser.Gender &&
      this.getRegFormControl("gender").setValue(this.previousRegUser.Gender);
    this.previousRegUser.ChildDob &&
      this.getRegFormControl("childDob").setValue(
        new Date(this.previousRegUser.ChildDob)
      );
    this.previousRegUser.LanguageId &&
      this.getRegFormControl("language").setValue(
        this.previousRegUser.LanguageId
      );
    this.previousRegUser.GuardianName &&
      this.getRegFormControl("guardianName").setValue(
        this.previousRegUser.GuardianName
      );
    mobile && this.getRegFormControl("mobileNo").setValue(mobile);
    countryCode && this.getRegFormControl("countryCode").setValue(countryCode);
    this.previousRegUser.Howdidyouhearaboutus &&
      this.getRegFormControl("howdidyouhearaboutus").setValue(
        this.previousRegUser.Howdidyouhearaboutus
      );
  }

  prepareUserRegModel() {
    this.UserModel.StartTime = new Date().toISOString();
    const date = this.getRegFormControl("childDob").value.toISOString();

    this.UserModel.ChildFName = this.getRegFormControl("inputFirstname").value;
    this.UserModel.ChildLName = this.getRegFormControl("inputLastname").value;
    this.UserModel.Gender = this.getRegFormControl("gender").value;
    this.UserModel.GuardianRole = this.getRegFormControl("guardianRole").value;
    this.UserModel.ChildDobStr = date;
    this.UserModel.LanguageId = this.getRegFormControl("language").value;
    this.UserModel.GuardianName = this.getRegFormControl("guardianName").value;
    this.UserModel.GuardianEmail =
      this.getRegFormControl("guardianEmail").value;
    this.UserModel.MobileNo =
      this.getRegFormControl("countryCode").value +
      " " +
      this.getRegFormControl("mobileNo").value;
    this.UserModel.Howdidyouhearaboutus = this.getRegFormControl(
      "howdidyouhearaboutus"
    ).value;
  }

  changeRoleSelection(id: string, value: string) {
    this.regForm.get(id).setValue(value);
  }

  getGenderRole() {
    return this.GUARDIAN_CHILD_RELATION[this.regForm.get("guardianRole").value];
  }
}
